.custom-popup {
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
  }
  
  .custom-popup h3 {
    margin: 0;
    font-size: 14px;
  }
  
  /* Harita altındaki attribution metnini gizle */
.leaflet-control-attribution {
  display: none !important;
}

/* Mobilde harita yüksekliği daha iyi görünmesi için */
@media screen and (max-width: 768px) {
  .map-container {
    height: 55vh !important;
  }
}

/* Popup tasarımını iyileştirme */
.custom-popup {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

