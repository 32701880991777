.pageNotFound{
    width: 100vw;
    height: 100vh;
    background-color: white;
    position: fixed;
    top:0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page_not_found_wrapper{
    text-align: center;
}

.main_heading{
    font-size: 55px;
    font-weight: 600;
    color: rgb(179, 56, 56);
    margin-bottom: 30px;
}

.back_to_home_btn{
    text-decoration: none;
    padding: 10px 40px;
    font-size: 19px;
    font-weight: 600;
    color: white;
    background-color: #F15929;
    border-radius: 30px;
    box-shadow: 1px 1px 5px .1px rgba(0,0,0,0.4);
}